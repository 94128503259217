(function(w, d, $) {

	class Site {

		constructor(d) {
			jQuery(d).ready($ => {
				this.onReady($);
			});
		}

		onReady($) {

			$('a[href^="#"]').on('click', (event) => {
				const target = $(this.attr('href'));
				if (target.length) {
					event.preventDefault();
					$('html, body').stop().animate({
						scrollTop: target.offset().top - 55
					}, 500);
				}
			});

			$('.swiper').each((index, el) => {
				const wrapped = $(el),
					slides = wrapped.data('slides') || 1;
				const swiper = new Swiper(el, {
					breakpoints: {
						768: {
							slidesPerView: slides,
						}
					},
					loop: true,
					spaceBetween: 16,
					autoplay: {
						delay: 15000,
						disableOnInteraction: true
					},
					pagination: {
						el: '.swiper-pagination',
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				});
			});
		}
	}

	w.site = new Site(d);

})(window, document, jQuery);
